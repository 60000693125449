/*eslint no-unused-vars: "off"*/

import React, { useContext, useEffect, useState, useRef, useMemo } from 'react';
import { StateContext, DispatchContext } from "./Contexts";
import { useTimer, useStateDelay } from './functions'
import { useKey, useLayer } from './Keyboard'
import Isam from './Isam'
import { formatDate, formatFloat, formatDateTime, formatTime, formatAll } from './format';
import { globals } from './globals';
import { ListCursor } from './ListCursor'
//import { MdCheckBox, MdCheckBoxOutlineBlank, MdIndeterminateCheckBox } from "react-icons/md";
import { FiCheckSquare, FiMinusSquare, FiSquare, FiMinus, FiCheck } from "react-icons/fi";
import { FaPen } from "react-icons/fa";

//import { withRouter } from "react-router-dom";
import processQueryString from "./processQueryString";
export default function ({ datakey }) {
	const state = useContext(StateContext);
	const [editId, setEditId] = useStateDelay(null, 10);
	const data = state.json[datakey] //.slice(state.listStart,state.listStart+state.listLength);
	//if(!state.json[datakey.replace(/^data/, 'def')]) return null
	//const [def] = useState(state.json[datakey.replace(/^data/, 'def')])
	const def = useMemo(() => {
		if (!state.json[datakey.replace(/^data/, 'def')]) return
		return state.json[datakey.replace(/^data/, 'def')]
			.map((item) => {
				//console.log('defmap');
				let cls = item.cls ? item.cls : '';
				cls += ' ' + item.att.replace(/[^a-z0-9]/gi, '-').replace(/[-]+$/g, '')
				return { ...item, cls };
			})
	}, [state.json]);
	var urlParams = new URLSearchParams(state.search);
	var ord = urlParams.get('ord');
	if (!def) return null
	if (ord === null || ord === '') ord = def && def.length > 1 && def[1].fid ? def[1].fid.toString() : '0';
	//	console.log('listview loading',state.isLoading);
	return (<>
		<div className={"wrapper-header"}>
			<Headings def={def} ord={ord} state={state} />
		</div>
		<div id="mainlist" className="wrapper-content" unselectable="true" style={{ userSelect: 'none' }}>
			<ListCursor data={data} settings={state.settings} editId={editId} setEditId={setEditId} />

			<Rows state={state} def={def} data={data} editId={editId} setEditId={setEditId} />
			{
				//<Refresher />
			}
		</div>
	</>
	);
}
function Refresher() {
	const dispatch = useContext(DispatchContext);
	const state = useContext(StateContext);
	let timer = false
	useEffect(() => {
		if (timer) clearTimeout(timer);
		timer = setTimeout(() => {
			//console.log('Refreshing');
			const after = (json) => {
				let data = [...json.data]
				//let idx = Math.floor(Math.random()*15);
				//data[idx].Code = 'Relaodededed!'
				//console.log(data);
				dispatch({ type: 'json-data-only', data });
			}
			dispatch({ type: 'post', method: 'GET', after })
		}, 5000);
		return () => {
			//console.log('Refresher cleared');
			if (timer) clearTimeout(timer);
		}
	}, [state.json.data]);

	return null
}
function Headings({ def, ord, state }) {
	const dispatch = useContext(DispatchContext);
	useEffect(() => {
		dispatch({ type: 'filter_set_start', clear: true });
	}, [state.pathname])
	const headings = def.map((item) => <Head state={state} dispatch={dispatch} key={item.id} item_id={item.id} def={item} ord={ord} keycode={item.id < 10 ? "Ctrl+" + item.id : ''} />);
	return <div className="row">{headings}</div>
}
//const Head = withRouter(
function Head({ dispatch, def, ord, keycode, state, item_id }) {
	//const [edit, setEdit] = useState(false);
	const onClick = () => {
		let neword = ord ? ord : '';
		neword = neword.replace(new RegExp(',?' + def.fid + 'd?', 'i'), '').replace(/^,/i, '');
		if (status === 'asc') neword = (def.fid + 'd') + (neword ? ',' + neword : '');
		//if (status === 'desc') neword = neword.replace(new RegExp(',?' + def.fid + 'd', 'i'), '');
		if (!status) neword = (def.fid + '') + (neword ? ',' + neword : '');
		dispatch({ type: 'querystring', querystring: { ord: neword, list_id: null, ise: null } })
	}
	useKey(keycode, onClick, []);
	const rightClick = (evt) => {
		dispatch({ type: 'rename', display: def.disp, name: def.name });
		evt.preventDefault();
	}
	let { cls } = def
	if (cls) {
		//console.log(cls)
		cls = cls.replace(/((^|[ ])(?!col-)(?!nvarchar)[^ ]+)/gi, ' ') //GB210103
		//console.log(cls)
	}
	//def.cls = def.cls ? ' ' + def.cls : '';
	//console.log(def.att.replace(/[^a-z0-9]/gi,'-').replace(/[-]+$/g,''))
	//def.cls += ' ' + def.att.replace(/[^a-z0-9]/gi, '-').replace(/[-]+$/g, '')
	//if (def.is_id || (def.name === 'id' && def.is_unq)) return null
	if (def.is_hid || (def.is_hid === null && (def.is_id || (def.name === 'id' && def.is_unq)))) return null
	if (def.is_hid) return null
	const drop = (evt) => {
		if (!state.settings.is_designer) { evt.preventDefault(); return }
		let def_id = evt.dataTransfer.getData('def_id');
		if (def_id == def.id) return; //gb231114
		let def_name = evt.dataTransfer.getData('def_name');
		let moveto = def.id * 2;
		moveto += def_id < def.id ? 1 : -1;
		//console.log('moveto', def_name, moveto)
		//console.log('drop', def_id, def_name, def.id);
		let after = () => { dispatch({ type: 'reload' }) }
		dispatch({ type: 'post', isLoading: true, pathname: state.pathname + '/' + (globals.selectedId ?? 0), search: '?edit=' + def_name, data: { "list_order": moveto }, after })
	}
	const dragstart = (evt) => {
		if (!state.settings.is_designer) { evt.preventDefault(); return }
		evt.dataTransfer.setData('def_id', def.id);
		evt.dataTransfer.setData('def_name', def.name);
	}
	const dragover = (evt) => evt.preventDefault();
	let status = false;
	if (def.fid) {
		status = ord.split(/[^0-9d]+/i).includes(def.fid.toString()) ? 'asc' : status;
		status = ord.split(/[^0-9d]+/i).includes(def.fid.toString() + 'd') ? 'desc' : status;
	}
	return <div onDragStart={dragstart} draggable onDrop={drop} onDragOver={dragover}
		className={(item_id > 2 ? "mhide" : "") + " col-sm m-0 p-1 text-truncate " + cls}>
		<button
			title={def.disp} tabIndex="-1"
			//onFocus={() => setEdit(true)}
			//onBlur={() => setEdit(false)}
			onClick={onClick}
			onContextMenu={rightClick}
			className={"text-left text-truncate m-0 pl-2 p-0 btn btn-" + (status !== false ? "success" : "secondary") + " w-100"}
		>{(status === 'desc' ? '▼ ' : '') + def.disp}</button>
		{keycode ? <div className="keycode mhide">{keycode}</div> : ''}
		<Reducer def={def} dispatch={dispatch} settings={state.settings} keycode={keycode.replace("Ctrl+", "Alt+")} />
	</div>
}
//);
function Reducer({ def, dispatch, keycode, settings }) {
	const [val, setVal] = useState('');
	const [expr, setExpr] = useState(0);
	const refInput = useRef(null);
	useEffect(() => {
		let urlParams = new URLSearchParams(window.location.search);
		let filter = urlParams.get('filter');
		let init = '';
		if (filter) {
			//let matches = filter.match(new RegExp('^.*?(' + def.name.toLowerCase() + ')[.]([^(]+)[(](.*?)(?<!\\\\)[)].*?$'))
			let matches = filter.match(new RegExp('(^|,)' + def.name + '[.]([^(]+)[(](([^)]|\\\\[)])+)[)]', 'i')) //'))
			//let matches = false
			if (matches) {
				//console.log(matches)
				init = matches[3].replace(/\\\)/g, ")")
				dispatch({ type: 'filter_set_start', key: def.name, val: init, expr: types[expr] });
			}
		}
		setVal(init)
	}, [def])
	useKey(keycode === 'Alt+2' ? (settings.noIsam ? "*" : " ") : false, () => {

		refInput.current.focus();
		setVal(' ')
	});
	useKey(keycode, () => {
		refInput.current.focus();
	}, []);
	const types = ['swhas', 'has', 'sw', 'eq'];
	const dp = () => dispatch({ type: 'filter', key: def.name, val, expr: types[expr] });
	function handleKeyUp(evt) {
		if (evt.altKey && evt.key === def.id) {
			refInput.current.focus();
			//console.log(evt.key)
		}
	}
	function submit(evt) {
		//console.log('Filter',evt.key);
		if (evt.key === "PageDown") {
			evt.target.blur();
			return
		}
		if (evt.key === "Escape") {
			evt.target.blur();
			evt.preventDefault();
			evt.stopPropagation();
			return false;
		}
		if (evt.key === "Enter") {
			dp();
			evt.target.blur();
			evt.stopPropagation();
			evt.preventDefault();
			return false;
		}
		if (evt.key === "ArrowDown") { // || evt.key === "Control" || evt.key === "Alt") {
			evt.target.blur();
		}
		//console.log(evt.key);
		//if (evt.key === "ArrowUp") if (expr > 0) { setExpr(expr - 1) } else { setExpr(types.length - 1) }
		//if (evt.key === "ArrowDown") if (expr < types.length - 1) { setExpr(expr + 1) } else { setExpr(0) }
	}
	/*
	useEffect(() => {
		window.addEventListener('keyup', handleKeyUp)
		return () => {
			window.removeEventListener('keyup', handleKeyUp)
		}
	})
	*/
	//console.log(def);
	return <>
		<input title={"Filter " + def.disp + (keycode ? ' (' + keycode + ')' : '') + "\n* = NULL\n! = NOT\n= = numeric equal\n> = numeric greater\n< = numeric lower\n\"abc\" = exact match"}
			placeholder={def.disp}
			arrowdown={def.name}
			ref={refInput}
			autoComplete="off"
			onFocus={evt => { if (evt.target.value !== ' ') evt.target.select(); }}
			onKeyDown={(evt) => submit(evt)}
			onChange={(evt) => {
				setVal(evt.target.value);
				dispatch({ type: 'filter_set_start', key: def.name, val: evt.target.value, expr: types[expr] });
			}}
			id={"heading_" + def.card + "_reducer_" + def.name}
			tabIndex="1"
			className="form-control"
			value={val} />
	</>
}
//		<input arrowdown={def.name} ref={refInput} onBlurOld={() => dp()} onKeyDown={(evt) => submit(evt)} onChange={(evt) => setVal(evt.target.value)} tabIndex="1" className="form-control" value={val} />

function Rows({ def, data, state, setEditId, editId }) {
	const [index, setIndex] = useState(false);
	//	const [identity, setIdentity] = useState(false);
	//const [showisam, setShowisam] = useState(false);
	const dispatch = useContext(DispatchContext);
	const { edit, search, selectedIdsByPath, pathname } = state;
	const selectedIds = selectedIdsByPath[pathname] ?? [];
	const [editField, setEditField] = useState(0);
	useEffect(() => {
		//console.log(index,editField)
		if (editField === 0) {
			//console.log('setEditField(1)', index);
			setEditField(1);
		}
	}, [editId]);
	useEffect(() => {
		if (editField === 0 && editId !== 0) setEditId(0);

	}, [editField]);

	useEffect(() => {
		let act = document.activeElement;
		if (act && act.tagName === "INPUT") return;

		let first = document.querySelectorAll("input[arrowup]")[0] ?? null;
		if (first) {
			first.focus();
			first.select();
		}
		//document.querySelectorAll("input[arrowdown]")[0].focus();
		//console.log('3434');
	}, [search]);
	useEffect(() => {
/*		if (data.length > 0) {
			var idx = false;
			for (var i = 0; i < data.length; i++) if (data[i]['id'] === state.lid) idx = i;
			if (idx) {
				console.log(idx);

			} else {
				setIndex(0);
			}
		}
*/		setIndex(false);
	}, [pathname]);

	const getCurrentId = (otherindex = null, otherdata = null) => {
		//console.log(data);
		let dt = otherdata ?? data;
		var current = 0;
		for (var key in def) {
			if (def[key].is_id) {
				var idx = otherindex !== null ? otherindex : index
				if (idx < 0) idx = 0;
				if (idx > dt.length - 1) idx = dt.length - 1;
				if (dt[idx] && dt[idx][def[key].name]) current = dt[idx][def[key].name];
				break;
			}
		}
		return current;
	}
	const gotoCard = (e, id) => {
		dispatch({ type: "set_selected_id", id });
		//document.dispatchEvent(new KeyboardEvent('keydown', { 'key': 'Enter' }));
		//keyEvent('body', 'keydown', 13);
		return
		//	if (state.hasPicklist) {
		//		picklist(data.id);
		//		return
		//	}
		if (e.ctrlKey) {
			dispatch({ type: "toggle_selected", id });
		} else {
			if (!state.isLoading) dispatch({ type: "pathname", pathname: pathname.replace(/[/]+$/, '') + '/' + id });
		}
	}

	/*
		const loadHandler = (index, data) => {
			//console.log('loadHandler', data)
			let curId = getCurrentId(index, data);
			//console.log(curId);
			if (curId) dispatch({ type: "querystring", querystring: { lid: curId } });
		}
		const [delayedIndex, setDelayedIndex] = useTimer(index, loadHandler, 500);
		*/
	/*
const [test, setTest] = useStateDelay(index, 500);
useEffect(() => {
	let curId = getCurrentId();
	//if (curId) dispatch({ type: "querystring", querystring: { lid: curId } });
	//console.log('test ok',test,curId);
}, [test]);
*/
	useEffect(() => {
		if (data && data[index] && data[index].id) {
			let id = data[index].id;
			//console.log('Listview setSelectedId id', id);
			globals.setSelectedId(id);
			//globals.selectedId=selectedId;
			//getVal("setSelectedId")(id);
		} else {
			if (data && data.length === 0) {
				//console.log('Listview setSelectedId 0', 0);
				globals.setSelectedId(0);
			}
		}
		//setTest(index);
		//setVal("selectedId",selectedId);
		//getVal("setSelectedId")(5);
		return

		if (data && data[index] && data[index].id) {
			if (data[index].id !== state.selectedId) dispatch({ type: "set_selected_id", id: data[index].id });
		}
		//console.log(data[index].id)
		//setDelayedIndex(index);
		//dispatch({ type: "set_selected_id", id: getCurrentId(index) });

		//		const [DelayedDispatch, setDelayedDispatch] = useTimer(data ?? '', postdata, 1000);
	}, [index, data]); // eslint-disable-line react-hooks/exhaustive-deps
	const user_id = state.settings && state.settings.user_id ? parseInt(state.settings.user_id) : 0;
	const toggeSelectedRows = (fromId, toId) => {
		let start = false
		data.map((item, i) => {
			if (item.id === fromId || item.id === toId) {
				start = !start
				dispatch({ type: "toggle_selected", id: item.id, selectRange: true });
			} else if (start) {
				dispatch({ type: "toggle_selected", id: item.id, selectRange: true });
			}
		});
	}
	const rowprops = { editField, setEditField, gotoCard, pathname, user_id, def, editId, setEditId, dispatch, toggeSelectedRows }
	const rows = useMemo(() => {
		return data.map((item, i) => <Row key={pathname + item.id + '_' + i} {...rowprops}
			cursor={!state.showisam && i === index} selected={selectedIds.includes(item.id)} data={item} />);
	}, [data, state, editId, editField, def, pathname])
	//console.log('wertwert2', Date.now() - strt)
	return (<>
		{state.showisam ? <Isam /> : ''}
		{rows}
	</>)

}

function Row({ data, def, cursor, selected, pathname, gotoCard, user_id, setEditId, editId, editField, setEditField, dispatch, toggeSelectedRows }) {
	const [showdata, setShowdataHere] = useState(data);
	const setShowdata = (d, df) => {
		if (d.length === 1) {
			if (!df) {
				setShowdataHere({ ...d[0] })
				return
			}
			let newdata = { ...data }
			for (let i = 0; i < df.length; i++) {
				let k = df[i].name
				//console.log('k', k, data[k])
				if (data[k] === undefined) continue;
				if (df[i].pl_typ === 2 || df[i].pl_typ === 3 || df[i].is_pl) {
					newdata[k] = df[i].extra ?? data[k]
				} else {
					newdata[k] = d[0][k] ?? data[k]
				}
			}
			//console.log('newdata', newdata)
			//for (let k in data) newdata[k] = d[0][k] ?? data[k]
			setShowdataHere(newdata)
			//console.log('setShowdata', data, d[0]);
		}
	}
	useEffect(() => { setShowdataHere(data) }, [data])
/*	const [editField, setEditField] = useState(0);
	useEffect(() => {
		if (editId === data.id) {
			setEditField(1);
		} else {
			if (editField !== 0) setEditField(0);
		}
	}, [editId]);
*/	let editableCount = 0;
	//console.log('editField',editField);
	const columns = /*useMemo(() => {return*/ def.map((item) => {
		const editable = item.cls && item.cls.match(/editable/gi) && true;
		if (editable) editableCount++
		return <Field id={showdata.id} editable={editable} setShowdata={setShowdata} editableCount={editableCount}
			focus={editId === showdata.id && editableCount > 0 && editableCount === editField}
			setEditField={setEditField} setEditId={setEditId} editField={editField} editId={editId} key={item.id + pathname}
			user_id={user_id} modifiedBy={showdata._mod_by_}
			data={showdata[item.name]} def={item} />
	});
	//}, [editId, data, editField, def]);
	useEffect(() => {
		//console.log(editField, editableCount);
		if (editField > editableCount) {
			setEditField(editableCount);
		}
	}, [editField, editableCount]);
	//var columns = <div>ok</div>
	//var columns = def.map((item) => <div key={item.id} className={"col-sm align-self-center text-truncate" + item.cls}>{data[item.name]}</div>);
	//if (data.id === 22995 || data.id === 1935) console.log('ertwert', data.id)
	return <div
		id={"id" + showdata.id}
		onClick={(e) => {
			e.preventDefault();
			e.stopPropagation();
			if (e.ctrlKey) {
				dispatch({ type: "toggle_selected", id: showdata.id });
				return
			}
			if (e.shiftKey) {
				toggeSelectedRows(globals.selectedId, showdata.id)
				//return
			}
			//await keyEvent('.my-input', 'keyup', 13);
			//gotoCard(e, showdata.id)
			if (showdata.id == globals.selectedId) {
				gotoCard(e, showdata.id)
			} else {
				//console.log('Listview setSelectedId showdata.id', showdata.id);
				globals.setSelectedId(showdata.id);
			}
			//gotoCard(e, showdata.id)
		}}
		//className={"row glow hover " + (cursor ? (selected ? 'bg-info' : 'bg-primary') + ' text-white' : '') + (selected ? 'bg-success text-white' : '')}
		className={"row hover" + (selected ? ' bg-info text-white' : '')}
	>{columns}</div>
}
function Field({ data, def, id, modifiedBy, user_id, focus, editField, setEditField, editableCount, setEditId, setShowdata, editId }) {

	const [hasFocus, setHasFocus] = useState(false); //useState(id === 162460);
	const [updates, setUpdates] = useState(0);
	const [datavalue, setDatavalue] = useState(data);
	useEffect(() => {
		if (hasFocus !== focus) setHasFocus(focus);
	}, [focus]);
	useEffect(() => {
		//		setUpdates(updates + 1);
		if (datavalue !== data) setDatavalue(data);
		//console.log(updates,data);
	}, [data]);
	useEffect(() => {
		if (updates > 1) {
			let timer = setTimeout(() => {
				setUpdates(1);
			}, 10000);
			return () => { clearTimeout(timer) };
		}
	}, [updates]);
	/*
	useEffect(() => {
		if (hasFocus) {
	
		} else {
	
		}
	}, [hasFocus]);
	*/
	//if(def.elem!==null) console.log(def);
	//	if (def.name === 'Telephonenr') return <Image def={def} data={data} />
	//	if (def.name === 'b64') return <ImageB64 def={def} data={data} />
	let [display, className, align, title, editable] = useMemo(() => {
		//console.log('whoops');
		let display = datavalue;
		let className = "col-sm px-1 text-truncate"
		//console.log(className);
		let align = "left";
		className += ' ' + def.cls + (updates > 1 ? ' cell-modified' : '')
		let matches = def.att.match(/^decimal\(([0-9]+),([0-9]+)/)
		if (def.att === 'float' || def.att.match(/int$/gi) || matches) { //gb240923 alle int: tiny/small/big
			className += ' no-ellipsis';
			if (!className.match(/(text-left|text-center)/)) {
				align = "right";
				className += ' text-right'; // pr-1 pl-0';
			}
		}
		if (display !== '...') {
			display = formatAll(datavalue, def.att, def.elem)
			//if (def.att.match(/^(date|time)/gi)) display = formatDate(datavalue);
			//if (def.att === 'time(7)' && display) display = display.substring(0, 5)
			//	if (def.att === 'datetime') display = formatDate(datavalue);
			//if (def.att === 'date') display = formatDate(datavalue);
			//if (def.elem === 'Date') display = formatDate(datavalue);
			//if (def.elem === 'Datetime') display = formatDateTime(datavalue);
			//if (def.elem === 'Time') display = formatTime(datavalue);
			if (def.att === 'float') display = formatFloat(datavalue);
			if (matches) display = formatFloat(datavalue, matches[2]);
		}
		try {
			matches = def.cls && def.cls.match(/match\(\/.*?[^\\]\/.*?\)/gi)
		} catch (err) {
			console.log(err);
		}

		if (matches) {
			matches.forEach(m => {
				className = className.replace(m, '')
				let m2 = false
				m2 = m.match(/^match\(\/(.*?[^\\])\/(.*?),(.*?)\)$/i)
				if (m2) {
					try {
						let regex = new RegExp(m2[1], m2[2]);
						if ((display === undefined || display === null ? '' : display).toString().match(regex)) className += ' ' + m2[3].replace(/^["']/gi, '').replace(/["']$/gi, '');
					} catch (e) {
						console.log(m2, def);
						console.log(e);
					}
				}
			})
		}

		let title = display;

		if (def.att === 'bit') {
			display = <FiMinus size="1.25em" style={{ opacity: 0.25 }} />
			title = def.disp + ': NULL'
			if (datavalue === true) { display = <FiCheck size="1.25em" />; title = def.disp + ': True'; }
			if (datavalue === false) { display = '\u00A0'; title = def.disp + ': False'; }//<FiSquare size="1.25em" />
		}
		const editable = className.match(/editable/gi) && true;
		if (display === null) display = '\u00A0';


		return [display, className, align, title, editable]
	}, [def, datavalue]);
	//if (def.is_id || (def.name === 'id' && def.is_unq)) return null
	if (def.is_hid || (def.is_hid === null && (def.is_id || (def.name === 'id' && def.is_unq)))) return null

	//if (editable) console.log(editable);
	//if (!display && def.id === 2) display = '\u00A0';
	//if (def.cls.match('listedit')) return <Input def={def} data={data} id={id} />
	//if (def.cls.match('listedit')) return <div className={"m-0 p-1 text-truncate col-sm" + def.cls}><input className={"p-0 form-control"} /></div>
	return (<>

		<div
			//onFocus={(evt) => {setHasFocus(true)}}
			//onBlur={() => setHasFocus(false)}
			onClick={evt => {
				if (editable) {
					//console.log(editField, editableCount);
					//if (id !== globals.selectedId) {
					setTimeout(() => {
						if (editField !== editableCount) setEditField(editableCount);
						if (id !== globals.selectedId) globals.setSelectedId(id);
						if (editId !== id) setEditId(id);
					}, 20)
					//evt.preventDefault();
					/*} else if (editField === 0) {
						setEditField(editableCount);
						setEditId(id);
					}*/
					evt.stopPropagation();
				} else {
					if (editField !== 0) { setEditField(0); setEditId(0); evt.stopPropagation(); }
				}
			}}

			//style={hasFocus?{backgroundColor:"#fff",color:"#888",borderRadius:"4px",outline:"none",zIndex:"2",boxShadow:"0 2px 5px #00000080"}:{}}
			//contentEditable={editable}
			title={title}
			style={hasFocus && editable ? { overflow: "visible" } : {}}
			className={className}>{def.id > 1 ? <span className="dhide mtag">{def.disp}</span> : ''}{def.id === 2 && modifiedBy ? <FaPen size={"11px"} style={{ marginLeft: "0px", marginTop: "0px" }}
				color={user_id === modifiedBy ? "#e06000" : null} /> : ''}{hasFocus && editable ?
					<InputLayer setShowdata={setShowdata} align={align} setEditField={setEditField}
						editField={editField} setDatavalue={setDatavalue} setHasFocus={setHasFocus} id={id} def={def} datavalue={datavalue} />
					:
					display}</div>
	</>);
}
function InputLayer({ datavalue, def, id, setHasFocus, setDatavalue, editField, setEditField, align, setShowdata }) {
	const [value, setValue] = useState(datavalue);
	const ref = useRef();
	//console.log(def);
	const state = useContext(StateContext);
	const dispatch = useContext(DispatchContext);
	//useLayer('field');
	const postValue = (val) => {
/*		if (datavalue != val) {
			console.log('val', datavalue, val)
			const after = (json) => {
				console.log(json);
			}
			let postdata = {}
			postdata[def.name] = val;
			postdata["api_action_post"] = { type: "save" };
			dispatch({ type: 'post', pathname: state.pathname + '/' + id, search: '', data: postdata, after })
			setDatavalue(val);
		}
		setHasFocus(false);
	*/	}
	useKey('*', (evt) => { console.log('aah', evt.key); });
	useKey('tab', (evt) => { evt.preventDefault(); setEditField(editField + 1) }, [])
	useKey('enter', (evt) => setEditField(0), [])
	useKey('escape', (evt) => setEditField(0), [])
	useKey('arrowright', (evt) => {
		//console.log('ja!');
		if (evt.target.type !== "text" || evt.target.selectionEnd === evt.target.value.length) {
			//console.log('ja!');
			setEditField(editField + 1);
			evt.preventDefault();
		}
	}, [], null, false) //gb240322 preventDefault = false
	useKey('arrowleft', (evt) => {
		if (editField > 0 && (evt.target.type !== "text" || evt.target.selectionStart === 0)) {
			setEditField(editField - 1);
			evt.preventDefault();
		}
	}, [], null, false) //gb240322 preventDefault = false
	useEffect(() => {
		return () => {
			let val = ref && ref.current && ref.current.value;
			if (def.att === 'bit') {
				//console.log('ref.current.indeterminate',ref.current.indeterminate);
				val = ref.current.indeterminate === true ? null : ref.current.checked;
				//console.log(datavalue);
			}
			//console.log('clean!', { datavalue, value, val });
			if (val === '' && !datavalue) return;
			if (val != datavalue) {
				//console.log('posing', val);
				let pathname = state.pathname + '/' + id
				let cardname = state.settings.cardname;
				const after = (json) => {
					//console.log(globals.selectedId)
					if (state.pathname !== window.location.pathname) return
					if (json.toasts) dispatch({ type: 'toasts', toasts: json.toasts });
					if (json.datarow) {
						let datarow = json.datarow[0];
						let data = state.json.data
						for (let i = 0; i < data.length; i++) {
							if (data[i].id === datarow.id) {
								if (data[i][def.name] === datarow[def.name]) {
									setDatavalue(datarow[def.name])
								}
								data[i] = datarow
							}
						}
						dispatch({ type: 'json-data-only', data });
					}

				}
				//GB210104 let postdata = {}
				//GB210104 postdata[def.name] = val;
				//GB210104 postdata["api_action_post"] = { type: "save" };
				//postdata["api_action_post"] = { type: "save" };
				let postdata = {
					"api_action_post":
					{
						"type": "stored_procedure",
						"initialPath": state.pathname,
						"name": "sys_list_editable",
						"id": id,
						"values": { "key": def.name, "val": val }
					}
				}
				//stored_procedure
				//console.log('post', state.pathname, val)
				dispatch({ type: 'post', pathname: state.pathname, search: '?data_only' /*state.search*/, data: postdata, after })
				setDatavalue('...');
			}
		}
	}, []);
	useEffect(() => {
		//if (newValue !== value) updateValue(newValue);
		if (def.att === "bit") ref.current.indeterminate = value === null;
	}, [value])
	let style = { outline: "none", zIndex: "2", position: 'absolute', height: 'auto', padding: "1px 11px", boxShadow: "0 2px 5px #00000080" }
	style[align] = '0px'
	let type = "text";
	let indeterminate = null;
	let onFocus = evt => { evt.target.select(); }
	let onChange = (evt) => setValue(evt.target.value)
	let inputValue = value ?? ''
	let checked = null
	const is_nullable = def.cls.match(/tristate/gi) && true;
	if (def.att === "bit") {
		onFocus = null;
		type = "checkbox";
		style["width"] = "100%"
		style["height"] = "20px"
		//console.log('chk', value, datavalue);
		inputValue = '';
		checked = value === true;
		//indeterminate = value === null ? "true" : "false";
		onChange = (evt) => setValue(value === null ? true : (value ? false : (is_nullable ? null : true)))
	}
	//checked={newValue === true}
	//type="checkbox"
	return <input
		ref={ref}
		//maxLength={12}
		autoFocus
		checked={checked}
		type={type}
		//indeterminate={indeterminate}
		//onKeyPress={evt=>{console.log('geert',evt.key)}}
		className={"form-control"}
		autoComplete="off"
		onFocus={onFocus}
		onBlur={evt => setEditField(0)}
		value={inputValue}
		style={style}
		onChange={onChange} />;
}
function Input({ def, data, id }) {
	const state = useContext(StateContext);
	const dispatch = useContext(DispatchContext);
	const { bearer } = state;
	//	console.log(data);
	const postdata = async (v) => {
		var headers = {}
		if (bearer) headers['Authorization'] = 'Bearer ' + bearer;

		var post = { "id": id }
		post[def.name] = v;
		dispatch({ type: 'fetch' });
		const response = await fetch(state.base + state.pathname + state.search, {
			method: 'POST',
			//credentials: "include",
			mode: 'cors',
			cache: 'no-cache',
			headers,
			body: JSON.stringify(post),
		});
		const json = await response.json();
		if (json.data_error || json.data) {
			if (json.data_error) {
				var message = '';
				json.data_error.forEach((row) => { message += row.message + "\n" })
				message = message.replace(/(,[a-z])/gi, "\n\t\t$1");
				dispatch({ type: 'error', message });
			} else {
				dispatch({ type: 'json', json });
			}
		}
	}
	const [val, setVal] = useTimer(data ?? '', postdata, 1000);


	//	const [value, setValue] = useState(data === null ? '' : data);
	return <input
		autoComplete="off"
		datatype={def.name}
		arrowdown={def.name}
		arrowup={def.name}
		placeholder={def.disp}
		onClick={(evt) => { evt.stopPropagation() }}
		onChange={(e) => setVal(e.target.value)}
		className={"col-sm form-control form-control-sm text-truncate " + def.cls}
		value={val} />
}
