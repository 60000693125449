import React, { useState, useContext, useEffect, useRef, useMemo, useCallback } from 'react';
import { DispatchContext, StateContext } from "./Contexts";
import { useStateDelay } from './functions'
import Warning from "./Warning";
//import Picklist from "./Picklist";
import InputMask from 'react-input-mask';
import { triggerKey, useKey } from './Keyboard'
import { useDidMount } from './functions'
import DatePicker from "react-datepicker";
import Money from "./elements/Money";
import Table from "./elements/Table";
import EditSense from "./elements/EditSense";
import Monaco from "./elements/Monaco";
import Html from "./elements/Html";

import { parseDate, getDateFormat, toLocalISOString, setCurrentDatpicker, formatFloat, formatTime, formatDateTime } from './format';
//import { Doughnut } from 'react-chartjs-2';
import BarChart from './Charts/BarChart'
import { globals } from './globals';

export default function ({ def, data, handler, disabled, type, state, focus, edit, setEditUpdatedfield, setUpdatedfield }) {
	if (!state) return null;
	if (data === undefined) return null;
	let size = 6;
	let cls = def.cls ? def.cls : ''
	let clssplit = cls.split("|", 2)
	def.outercls = clssplit.length === 2 ? clssplit[0] : cls
	def.innercls = clssplit.length === 2 ? clssplit[1] : cls
	//console.log(disabled);
	if (def.elem === 'Email') def.regex = '^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+.(?:\\.[a-zA-Z0-9-]+)+$';
	if ((!handler && !disabled) && def.elem === 'Html') return <Html data={{ html: data, className: def.cls }} />
	if (def.is_id) return null
	if (def.elem === null && def.att === "nvarchar(max)") def.elem = 'Textarea';
	if (def.elem === null && def.att === "bit") def.elem = 'Checkbox';
	//if (def.elem === null && def.att === "datetime2") def.elem = 'Date';
	//if (def.elem === null && def.att.substring(0, 8) === "datetime") def.elem = 'Date';
	if (def.elem === null && (def.att.substring(0, 9) === "datetime2" || def.att.substring(0, 10) === "datetimeof" || def.att === "smalldatetime")) def.elem = 'Datetime';
	if (def.elem === null && def.att === "datetime") def.elem = 'Date';
	if (def.elem === null && def.att === "date") def.elem = 'Date';
	//if (def.elem === null && def.att === "time") def.elem = 'Time';
	if (def.elem === 'Textarea' || def.elem === 'Monaco' || def.elem === 'Table' || def.elem === 'Sql' || def.elem === 'EditSense') size = 12;
	//if (def.elem === 'Doughnut') size = 12;
	if (def.elem === 'Checkbox') size = 6;
	if (def.elem === 'Warning') return <Warning message={data} />
	//if(def.extra) console.log(def.extra);
	return (
		<Outer def={def} size={size} handler={handler} focus={focus} edit={edit} ismobile={state.ismobile} origdata={data} disabled={disabled} settings={state.settings} setUpdatedfield={setUpdatedfield} pathname={state.pathname}>
			<div style={{ position: "absolute", textShadow: "0 0 3px #fff", marginLeft: 1, color: '#88f', opacity: 1, marginTop: -2, zIndex: 1, fontSize: 11, fontStyle: "italic" }}>{def.elem !== 'Checkbox' && data === null ? "*" : ""}</div>

			{handler ? <div className="pre">{data}</div> :
				def.elem === 'Text' ? <Text def={def} data={data} />
					: <Input def={def} pathname={state.pathname} isLoading={state.isLoading} origdata={data} disabled={disabled} edit={edit} settings={state.settings} setEditUpdatedfield={setEditUpdatedfield} />
			}
		</Outer>
	);
}
/*
function DropImage({ data, def }) {
	//const [dragover, setDragover] = useState('');
	const [imageData, setImageData] = useState();
	const dispatch = useContext(DispatchContext);
	let { cls } = def
	useEffect(() => {
		const pasteFunction = (evt) => {
			//console.log(evt.clipboardData.types);
			//console.log(evt.clipboardData.getData('text/html'))
			//console.log(evt.clipboardData.getData('text'))
			//console.log(evt.clipboardData);
			var items = evt.clipboardData.items;
			for (var i = 0; i < items.length; i++) {
				// Skip content if not image
				if (items[i].type.indexOf("image") === -1) continue;
				let file = items[i].getAsFile();
				let reader = new FileReader();
				reader.onload = function (e) {
					let values = {}
					values['@name'] = file.name
					values['@mimetype'] = file.type
					values['@modified'] = file.lastModifiedDate.toISOString()
					values['@filesize'] = file.size
					values['@base64data'] = e.target.result.split(';base64,', 2)[1]
					dispatch({ type: 'stored_procedure', name: 'sys_file_upload', values })
				}
				reader.readAsDataURL(file);
			}
		}
		window.addEventListener("paste", pasteFunction, true);
		return () => {
			//console.log("Keyboard end")
			window.removeEventListener("paste", pasteFunction, true);
		}

	})
	const uploadFiles = (files) => {
		let file = files.pop();
		let reader = new FileReader();
		reader.onload = function (e) {
			let values = {}
			values['@name'] = file.name
			values['@mimetype'] = file.type
			values['@modified'] = file.lastModifiedDate.toISOString()
			values['@filesize'] = file.size
			values['@base64data'] = e.target.result.split(';base64,', 2)[1]
			dispatch({ type: 'stored_procedure', name: 'sys_file_upload', values })
			if (files.length) setTimeout(() => uploadFiles(files), 1000);
		}
		reader.readAsDataURL(file);
	}
	const onDrop = useCallback(acceptedFiles => {
		dispatch({ type: 'set_setting', key: 'updatedfield', value: def.fid });
		uploadFiles(acceptedFiles);
		//console.log(acceptedFiles);
		//let file = acceptedFiles[0]
		//acceptedFiles.forEach((file) => {
		//})
	}, [])
	const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

	//if (!cls) cls = 'col-sm-6'
	return <div {...getRootProps()}
		className={"droparea " + cls} >
		<input {...getInputProps()} />
		DropImage DATA: [{data}]
		{
			isDragActive ?
				<p>Drop the file(s) ...</p> :
				<p>Drag file(s) here ...</p>
		}
		{imageData ? <img className="mw-100" src={imageData} /> : ''}
	</div>

}
*/
function Text({ data, def }) {
	let { innercls } = def
	if (!innercls) innercls = 'col-sm-6'
	return <div className={innercls} >{data ? data : "\u00a0"}</div>
}

function Outer({ def, size, handler, origdata, disabled, children, pathname, edit, focus, setUpdatedfield, ismobile, settings }) {
	//console.log(disabled);
	const dispatch = useContext(DispatchContext);
	let colprefix = "col-sm-";
	let col1 = colprefix + "4 p-2";
	let col2 = colprefix + "8 p-2";
	let link = def.link;
	//if (!link && def.pl_typ) link = pathname + '/' + def.fid;
	if (size === 12) {
		col1 = colprefix + "2 p-2";
		col2 = colprefix + "10 p-2";

	}
	if (size === 3) {
		col1 = colprefix + "8 p-2";
		col2 = colprefix + "4 p-2";

	}
	//console.log(edit,handler,disabled)
	if (def.elem === 'BarChart' && !disabled) {
		col1 = colprefix + "8 d-none p-2";
		col2 = colprefix + "12 col-sm p-2";

	}
	const rightClick = (evt) => {
		dispatch({ type: 'rename', /* 100820 context: def.tbl,*/ display: def.disp, name: def.name, fid: def.fid });
		evt.preventDefault();
	}
	const dragstart = (evt) => {
		if (!settings.is_designer) { evt.preventDefault(); return }
		evt.dataTransfer.setData('def_id', def.id);
		evt.dataTransfer.setData('def_name', def.name);
	}
	const drop = (evt) => {
		if (!settings.is_designer) { evt.preventDefault(); return }
		let def_id = evt.dataTransfer.getData('def_id');
		let def_name = evt.dataTransfer.getData('def_name');
		let moveto = def.id * 2;
		moveto += def_id < def.id ? 1 : -1;
		let after = () => { dispatch({ type: 'reload' }) }
		dispatch({ type: 'post', isLoading: true, pathname: pathname, search: '?edit=' + def_name, data: { "detail_order": moveto }, after })
	}
	const dragover = (evt) => {
		if (evt.dataTransfer.types.includes('def_id')) evt.preventDefault();
	}
	return (
		<div className={"col-sm-" + size + ' ' + def.outercls}>
			<div className={"row" + (focus ? ' focus' : '')} fid={def.fid} arrow-skip={(def.elem === 'Text').toString()} onClick={() => setUpdatedfield(def.fid)}>
				<div onDragStart={dragstart} draggable onDrop={drop} onDragOver={dragover} onContextMenu={rightClick} className={col1 + (def.is_mod ? ' modified' : '')}>
					{!handler && disabled ?
						<>
							<textarea className={"form-control"} rows={1} style={{ userSelect: 'none', resize: 'none', padding: 0, margin: 0, backgroundColor: '#fff0', border: 0 }} onClick={(evt) => {
								//let elem = document.createElement("input");
								//elem.value='@' + def.name;
								//elem.focus();
								evt.target.select();
								//document.execCommand('copy');
								//console.log('suc',suc)
								//window.prompt("Copy to clipboard: Ctrl+C, Enter", '@' + def.name)
							}} defaultValue={'@' + def.name}></textarea>
							<button
								style={{ fontSize: '80%', display: 'block', position: 'absolute', lineHeight: '14px', textAlign: 'left', color: '#bbb', marginTop: '-6px' }}
								onClick={() => {
									if (window.getSelection) { window.getSelection().removeAllRanges(); }
									else if (document.selection) { document.selection.empty(); }
									dispatch({ type: 'querystring', querystring: { edit: def.name } })
								}}
								className={'p-0 btn btn-link'} >{def.id * 2}.{def.name}{def.is_pl ? ('.' + (def.pid === null ? 'NULL' : def.pid)) : ''}<br />{def.att}
								{def.is_nul ? '' : <i style={{ color: 'red' }}> NN</i>}
								{def.att === 'int' && !def.pl_typ ? <i style={{ color: 'red' }}> <b>PL?</b></i> : ''}
							</button>

						</> :
						<>
							{link ? <button tabIndex="-1"
								onClick={(evt) => {
									if (evt.ctrlKey) {
										let win = window.open(link, '_blank');
										return true
									} else {
										dispatch({ type: 'pathname', pathname: link })
									}
								}}
								title={def.disp}
								className={'p-0 btn btn-link'}>{def.disp}</button> : def.disp}

							{!ismobile && def.is_mod && def.is_mod !== settings.user ? <div style={{ fontSize: '80%', display: 'block', position: 'absolute', lineHeight: '14px', textAlign: 'left', color: '#f80', marginTop: '0px' }}>
								{def.is_mod}</div>
								: !ismobile && def.mask ? <div className="form-view-mask">
									{def.mask}</div> : ''}
						</>

					}


				</div>
				<div draggable={false} className={col2}>
					{!ismobile && def.is_mod && focus && !edit ? <div className="keycode">Delete</div> : ''}
					{!ismobile && !def.is_mod && focus && !edit && origdata !== null && def.is_nul && def.is_upd ? <div className="float-right opacity-75"><div title="Backspace to clear value (= null)" className="keycode">Backspace</div></div> : ''}
					{children}
				</div>
			</div>
		</div>
	);
}

function Input({ def, origdata, disabled, settings, edit, setEditUpdatedfield, pathname, isLoading }) {
	const didMount = useDidMount();
	//console.log('origdata',origdata);



	const data = origdata === null || origdata === undefined ? origdata === undefined ? '[NO DATA!!!]' : '' : origdata.toString();
	//console.log('orig2',def.name,data);
	const [value, setValue] = useState(data);
	const [updateValueDelayedTimer, setUpdateValueDelayedTimer] = useState(false);

	const [valueDelay, setValueDelay] = useStateDelay(data, 400);
	const [updated, setUpdated] = useState();
	const refInput = useRef(null);
/*	function handleKeyDown(evt) {
		if (refInput.current && refInput.current.focus && def.disp && evt.altKey && evt.key.toLowerCase() === def.disp.charAt(0).toLowerCase()) {
			refInput.current.focus();
			// console.log(evt.key)
		}
	}
    const [initValue, setInitValue] = useState(data);
    if(initValue!==data){
        setInitValue(data);
        setValue(data);
    }
*/  const dispatch = useContext(DispatchContext);
	/*	useEffect(() => {
			if(valueDelay===value)
		},[valueDelay])
	*/
	useEffect(() => {
		if (updated === null) {
			setUpdated(false);
		} else {
			//console.log('updated', value);
		}
	}, [value, updated])
	//console.log('updated', updated);
	useEffect(() => {
		//console.log("edit toggled", edit, didMount);
		if (didMount) {
			//console.log('mount')
			let input = refInput && refInput.current;
			if (input && !input.focus && input.input) input = input.input;
			//console.log(refInput.current);
			if (input && input.focus) {
				if (edit) {
					if ((input.tagName === 'INPUT' && input.getAttribute('type') === 'checkbox') || (input.tagName === 'BUTTON')) {
						setEditUpdatedfield(false);
						input.click();
						return;
					} else if (input.tagName === "INPUT") {
						input.select();
					}
					//console.log("input.focus()", input);
					input.focus();
				} else {
					//console.log("input.blur()", input);
					setCurrentDatpicker(input)
					input.blur();
				}
			} else {

				//refInput.current.focus();
				console.log(def.name, 'refInput problem!', input, edit);
			}
		}
	}, [edit]);
	//const state = useContext(StateContext);
	let type = def.att === 'bit' ? "checkbox" : "text";
	if (def.elem === 'Time') type = 'time';
	if (def.elem === 'Datetime') type = 'datetime-local';
	if (def.elem === 'Date') type = 'date';
	let mask = def.mask
	let numeric = false
	let maxLength = def.len < 1 ? 1000000 : def.len
	if (def.att.substring(0, 4) === 'time') {
		if (def.len !== 5) maxLength = 8
	}
	if (def.att === 'float' || def.att === 'int') {
		numeric = true
		maxLength = 10
	}
	let regex = def.regex
	//if(regex)  maxLength = 1000000
	let placeholder = maxLength < 30 ? ("_ ".repeat(maxLength)) : ""
	let step;
	if (type === 'datetime-local') {
		if (def.att === "smalldatetime") step = 60
		else step = 1
	}
	if (!mask && def.att.match(/^decimal\(([0-9]+),([0-9]+)/)) {
		numeric = true

		//if (value.match(/[.]/)) setValue(value.replace(".", ","))
		let matches = def.att.match(/^decimal\(([0-9]+),([0-9]+)/)
		maxLength = matches[1] + 1
		placeholder = "_ ".repeat(matches[1] - matches[2] - 1) + "0 ";
		if (!regex) {
			regex = "^-?[0-9.,]{" + (def.is_nul ? "0" : "1") + "," + (matches[1] - matches[2]) + "}";
			if (matches[2] !== "0") {
				regex += '([.,][0-9]{0,' + matches[2] + '})?'
				placeholder += ", " + ("_ ".repeat(matches[2]));
				maxLength++
			}
			regex += '$'
		}
		//console.log(regex)
		//console.log(matches[1],matches[2]);
		//type="number"
		//mask=null;
		//step="any"
	}
	if (mask) maxLength = null;

	const setOrigdata = (origdata) => {
		let val = origdata === null || origdata === undefined ? (def.fill ? def.fill : '') : origdata.toString()

		//console.log(val, def.att)

		if (def.elem === "Date" && settings.useHtmlNativeDatepicker) {
			val = val.split(/T/, 2)[0];
		}
		if (def.att === 'float') {
			val = formatFloat(val);
		} else {
			let matches = def.att.match(/^decimal\(([0-9]+),([0-9]+)/)
			if (matches) {
				val = formatFloat(val, matches[2]);
			}
		}
		if (def.elem === 'Datetime' && def.att.match(/^datetime/gi)) {
			//val = formatDateTime(val)
			//if (def.att === 'datetime2(3)') {
			//step = '.001'
			//	val = val.replace(/^([^.]+)([.][0-9]{3})?.*$/, '$1$2');
			//if (!val.match(/[.]/gi)) val += '.001'
			//} else {
			val = val.replace(/^([^.]+).*$/, '$1');
			//console.log(val, def.att)
			//}

		} else if (def.elem === 'Time' && def.att.match(/^date/gi)) {
			val = formatTime(val)
		} else if (def.att === 'time(7)') {
			val = val.substring(0, 5)
		}
		//console.log(val, def.att)

		//console.log('origdata', def.name, origdata,def.fill);
		setValue(val);
	}
	//console.log(def.name, origdata, value, data);
	useEffect(() => {
		//console.log(def.name, origdata, value);
		setOrigdata(origdata);
	}, [origdata]);// eslint-disable-line react-hooks/exhaustive-deps
	try {
		var re = new RegExp(regex, 'g');
	} catch (e) {
		//if (!state.error) dispatch({ type: 'error', message: def.name + ': ' + e });
		console.log('RegExp ERROR', def.name, e);
	}
	if (origdata === undefined) {
		console.log('undefined', def);
		//alert('No data...? Check console...');
		return null
	}

	/*
	useEffect(() => {
		window.addEventListener('keydown', handleKeyDown)
		return () => {
			window.removeEventListener('keydown', handleKeyDown)
		}
	})
	*/
	const onFocus = ({ refInput }) => {
		if (refInput === null) return
		//return
		//console.log('Focus', def.name)
		setCurrentDatpicker(refInput);
		let input = refInput && refInput.current;
		//console.log('Focus', input);return;
		if (!input.focus && input.input) input = input.input;
		//console.log('Focus', input)
		//		return
		dispatch({ type: 'set_setting', key: 'updatedfield', value: def.fid });
		if (input && (input.tagName === "INPUT" || input.tagName === "TEXTAREA")) {
			if (input.tagName === "INPUT") {
				//console.log(origdata, value);
				//				if (origdata == value) input.select(); //LET OP! express == ipv === omdat 600 == '600'
			}
			setEditUpdatedfield(true);
		}
	}
	//var invalid = def.regex !== null && !data.match(re);
	var valid = (regex === null || value.match(re)) ? true : false; //) && def.valid !== false;
	//console.log('valid', valid);
	//if (def.regex !== null) console.log(def.regex);
	//let step
	const updateValue = (val) => {
		if (updateValueDelayedTimer) clearTimeout(updateValueDelayedTimer)
		//console.log(data);
		//console.log(def);
		//return;
		let postval = val;

		if (def.elem === "Date" && settings.useHtmlNativeDatepicker && origdata) {
			let tmp = data.split(/T/, 2);
			if (tmp.length === 2) postval += 'T' + tmp[1];
		}

		if (numeric) {
			postval = postval.replace(/[^0-9.,-]/gi, '');
			if (postval.match(/[.][0-9]*[,]/gi)) {
				postval = postval.replace(/[.]/gi, '');
			} else if (postval.match(/[,][0-9]*[.]/gi)) {
				postval = postval.replace(/[,]/gi, '');
			}
			postval = postval.replace(/[,]/gi, '.')
			//console.log('postval', postval, data === null, data, origdata);
			if (postval === '') {
				setOrigdata(origdata);
				return
			}
			postval = parseFloat(postval) + '';
			if (postval === '0' && data === '' && def.elem !== 'Select') {
				setOrigdata(origdata);
				return
			}
			//console.log('postval', postval, data === null, data, origdata);
		}
		//console.log(postval,def);
		if (type === 'datetime-local') {
			postval = toLocalISOString(postval);

		}
		//console.log(postval,def);
		if (def.att.substring(0, 4) === 'time' || type === 'time') {
			postval = postval.replace(/[^0-9]+/, "") //alle onzin eruit
			postval = postval.padStart(4, '0') //vul links aan met nullen, dus 51 wordt 0051
			if (postval.length === 4 && parseInt(postval) > 2359) postval = postval.padStart(6, '0') //maak er minuten en seconden van boven 2359
			postval = postval.padEnd(6, '0') //vul daarna rechts aan met nullen, dus 0051 wordt 005100
			if (parseInt(postval.substring(0, 2)) > 23) postval = '23' + postval.substring(2, 6) //uren niet boven 23
			if (parseInt(postval.substring(2, 4)) > 59) postval = postval.substring(0, 2) + '59' + postval.substring(4, 6) //minuten niet boven 59
			if (parseInt(postval.substring(4, 6)) > 59) postval = postval.substring(0, 4) + '59' //seconden niet boven 59
			postval = postval.replace(/^([0-9]{2})[:]?([0-9]{2})[:]?([0-9]{2}).*/, "$1:$2:$3") // maak er een tijd van: 00:51:00
		}
		if (def.att.match(/time/gi) && origdata && postval && !postval.match(/[.]/gi)) {
			let tmp = data.split(/[.]/, 2);
			if (tmp.length === 2) postval += '.' + tmp[1];
		}

		//console.log(data, postval)
		//if(type) console.log(value);
		if ((data === null ? '' : data) !== postval) {


			//			console.log(data, val, def,value)
			//if (numeric) val = val.replace(",", ".")
			//console.log('yeah!2')
			//console.log(data.replace(/\r/g,''));
			//console.log(postval.replace(/\r/g,'rrr'));
			//console.log(postval);
			if (def.is_upd !== false) {
				if (def.elem !== 'Textarea' || data.replace(/\r/g, '') !== postval) { //GB230710
					//console.log('save');
					dispatch({ type: 'save', key: def.name, val: postval });
				}
			}
		} else {
			setOrigdata(origdata);
		}
		//console.log('yeah!2')
		if (value !== val) {
			//if(def.elem !== 'Textarea') 
			setValue(val);
			//
		}
	}
	//let updateValueDelayedTimer = false;
	const updateValueDelayed = (val) => {
		return //even niet, het is buggy
		if (updateValueDelayedTimer) clearTimeout(updateValueDelayedTimer)
		let timer = setTimeout(() => updateValue(val), 5000)
		setUpdateValueDelayedTimer(timer)
	}

	if (!disabled && def.is_mod && def.is_mod !== settings.user) {
		placeholder = 'Currently in use by ' + def.is_mod
		disabled = true;
	}
	let extra;
	try {
		extra = JSON.parse(def.extra);
	} catch (e) {
		extra = def.extra;
	}
	let className = "form-control " + def.innercls + (valid ? '' : ' is-invalid');
	const fieldProps = { def, className, pathname, onFocus, disabled, mask, type, refInput, updateValue, setValue, valid, settings, extra, value: origdata, isLoading, updateValueDelayed, dispatch, setEditUpdatedfield }
	if (type === "checkbox") return <Checkbox  {...fieldProps} value={origdata} data={data} />
	if (def.pl_typ === 2 || def.pl_typ === 3 || def.is_pl) return <PicklistInput {...fieldProps} data={def.extra} />
	//if (def.is_pl) return <PicklistInput  />
	if (def.elem === "Select") return <Select {...fieldProps} value={value} data={data} />

	//if (def.elem === "Doughnut" && extradata) return <Doughnut {...extradata} />
	if (def.elem === "BarChart") return <BarChart {...fieldProps} />
	//BarChart
	//if (def.elem === "DropImage") return <DropImage {...fieldProps} value={value} data={data} />
	if (def.elem) {
		if (def.elem === "Image") return <Image {...fieldProps} value={value} data={data} />
		if (def.elem === "Textarea" || def.elem.toLowerCase() === "textareasmall") return <Textarea {...fieldProps} value={value} data={data} />
		if (def.elem === "EditSense") return <EditSense {...fieldProps} value={value} data={data} />
		if (def.elem === "Sql") return <Sql {...fieldProps} value={value} data={data} />
		if (def.elem === "Monaco") return <Monaco {...fieldProps} value={value} data={data} />
		if (def.elem === "Date" && !settings.useHtmlNativeDatepicker) return <DateInput {...fieldProps} value={value} data={data} />
		if (def.elem === "Money") return <Money {...fieldProps} />
		if (def.elem === "Table") return <Table {...fieldProps} />
		if (def.elem === "InputMask") return <InputMask {...fieldProps} />
	}
	//return <InputMask mask={mask}
	return <input
		step={step}
		ref={refInput}
		type={type}
		//name={globals.randomString}
		placeholder={placeholder}
		title={placeholder}
		//defaultChecked={data}
		//autoComplete={def.name}
		autoComplete="off"
		//autoFocus={def.id === 2 && !disabled}
		//autoFocus={edit}
		//autoFocus={(def.id === 2 && !disabled) || (settings.updatedfield && def.fid === parseInt(settings.updatedfield))}
		value={value}
		disabled={disabled}
		className={className + (valid ? '' : ' is-invalid') + (def.valid !== null ? (def.valid === false ? ' is-invalid' : '') : '')}
		//className={"form-control "
		//+ (def.is_mod ? disabled ? 'is-touched-blue ' : 'is-touched ' : '')
		onKeyDown={(e) => {
			if (e.key === "Insert") {
				dispatch({ type: 'pre_action', action: 'save' });
				e.target.blur()
			}
		}}
		onChange={(e) => {
			if (!disabled) setValue(e.target.value);
			if (!disabled && valid) updateValueDelayed(e.target.value);
		}}
		onBlur={(e) => {
			if (document.hasFocus()) {
				setEditUpdatedfield(false);
				if (!disabled && valid) updateValue(e.target.value);
			} else {
				e.preventDefault();
				e.stopPropagation();
				return false;
			}
		}}
		onFocus={(evt) => {
			onFocus({ evt, refInput })
		}}
		readOnly={!def.is_upd}
		tabIndex={def.is_upd ? "1" : "-1"}
		maxLength={maxLength}
	//onKeyDown={}
	/>
}
export function Image({ def, onFocus, value, updateValue, disabled, refInput }) {
	let extra = {};
	try {
		extra = JSON.parse(def.extra);
	} catch (e) {
		console.log(e);
		//extra = [{ message: 'ERROR: ' + def.extra }]
	}
	return <img {...extra} />
}

export function Select({ def, onFocus, value, updateValue, disabled, valid, refInput }) {
	let extra = [];
	try {
		extra = JSON.parse(def.extra);
	} catch (e) {
		extra = [{ message: 'ERROR: ' + def.extra }]
	}

	let keys = [];
	//console.log(extra);


	if (extra && extra.length && typeof extra[0] === "object") {
		if (value !== null && !extra.some(element => { return element.id === value })) extra.push({ id: value, name: value + '*' })
		keys = Object.keys(extra[0]);
	} else if (extra && extra.length) {
		extra = extra.map(val => { return { name: val } })
		keys = ["name"]
	} else {
		extra = [];
	}
	//console.log(keys,extra);
	//console.log(value);
	const options = extra.map((item, i) => {
		let key = keys.length > 1 ? item[keys[0]] + item[keys[1]] + i : item[keys[0]];
		let name = keys.length > 1 ? item[keys[1]] : item[keys[0]];
		let value = item[keys[0]];
		if (key === undefined) key = 'key' + i;
		//console.log(item, key);
		//if(value === item.value) return <option  value={item.value}>{item.name}</option>
		return <option key={key} value={value}>{name}</option>
	})
	if (!onFocus) onFocus = () => { }
	return <select
		tabIndex={def.is_upd ? "1" : "-1"}
		ref={refInput}
		disabled={!def.is_upd || (def.is_upd && disabled)}
		value={value ?? ''}
		onFocus={(evt) => onFocus({ evt, refInput })}
		onChange={(e) => { if (!disabled) { updateValue(e.target.value) } }}
		//+ (def.is_mod ? 'is-touched ' : '')
		className={"form-control " + (def.valid === false ? ' is-invalid' : '')}>
		<option value={''}>Choose...</option>{options}
	</select>
}
function PicklistInput({ def, onFocus, data, disabled, type, refInput, updateValue, settings, pathname, isLoading }) {
	const [value, setValue] = useState(data === null ? '' : data);
	//const [showPicklist, setShowPicklist] = useState(false);
	//const [startpath, setStartpath] = useState(pathname);
	const dispatch = useContext(DispatchContext);
	useEffect(() => {
		setValue(data);
	}, [data])
	const closelist = () => {
		//setShowPicklist(false);
		refInput.current.focus();
	}
	const openlist = (evt, key) => {
		if (isLoading) return;
		//GB 200917 if (!def.link && def.pl_typ) {
		if (def.pl_typ) {
			//dispatch({ type: 'pathname', addto: '/' + def.fid })
			dispatch({ type: 'pathname', pathname: pathname + '/' + def.fid, search: '?red=' }) //GB 201125 remove reducer

			//link = state.pathname + '/' + def.fid;
			//console.log(state.pathname)
		} else {
			refInput.current.blur();
			// if (def.elem === 'Picklist') {
			//setShowPicklist(true);
		}
		//    } else {
		//       dispatch({ type: 'querystring', querystring: { pl: def.name, lid: def.pid, ise: null } })
		//   }
	}
	//console.log(def.name, def.is_nul, data);
	let className = "btn form-control text-truncate text-left ";
	className += def.valid === false || (def.is_nul === false && data === null) ? "btn-danger" : "btn-dark"
	//className += def.is_mod ? ' btn-tracy' : '';
	//console.log(value,def)
	//{showPicklist ? <Picklist updateValue={updateValue} closelist={closelist} name={def.name} pid={def.pid} /> : ''}
	return <>
		<button
			ref={refInput}
			disabled={!def.is_upd || disabled}
			readOnly={!def.is_upd}
			tabIndex={def.is_upd ? "1" : "-1"}
			onClick={openlist}
			//autoFocus={def.id === 2 || (settings.updatedfield && def.fid === parseInt(settings.updatedfield))}
			onFocus={(evt) => onFocus({ evt, refInput })}
			onKeyDown={evt => { if (evt.key === 'Enter') openlist() }}
			className={className}
		>{value}</button>
	</>;
}
function escapeHTML(unsafeText) {
	let div = document.createElement('div');
	div.innerText = unsafeText;
	return div.innerHTML.replace(/<br>/gi, "\r\n");
}
function Sql({ def, onFocus, data, disabled, value, setValue, updateValue, refInput, setEditUpdatedfield }) {
	const [hasFocus, setHasFocus] = useState(false);
	const [hasHadFocus, setHasHadFocus] = useState(false);
	const [resultList, setResultList] = useState();
	const [lastKey, setLastKey] = useState();

	const refDiv = useRef();
	let rows = def.rows;
	let svalue = value;
	const [words, wordregex] = useMemo(() => {
		let wordlist = "AS|EXEC|WHERE|GETDATE|CAST|RETURN|EXCEPT|RAISERROR|EXECUTE|ABS|CONCAT_WS|EXISTS|SUM|JOIN|CROSS APPLY|FORMAT|JSON|CONVERT|NULLIF|DELETE|LEFT|INSERT|GOTO|SCOPE_IDENTITY|GROUP BY|ON|CASE|WHEN|THEN|SYSDATETIME|ISNULL|QUOTENAME|PATH|AUTO|INCLUDE_NULL_VALUES|WITHOUT_ARRAY_WRAPPER|DISTINCT|UPDATE|TRY_CAST|STRING_AGG|NULL|PRINT|IS|NOT|OR|FETCH|WHILE|SELECT|SET|OPEN|DECLARE|IIF|IF|ELSE|BEGIN|AND|END|NEXT|CLOSE|DEALLOCATE|CURSOR|FROM|FOR|ORDER BY|DESC|CONCAT|OUT|INTO|IN|LIKE";
		let extrawords = def.extra ?? "";
		let words = (wordlist + '|' + extrawords).split("|");
		let wordregex = new RegExp("(^|[^a-z@_])(" + wordlist + ")(?![a-z@_])", "gi")
		return [words, wordregex]
	}, [def]);
	/*useKey('Tab',(evt)=>{
		console.log('tab');
		evt.preventDefault();
		evt.stopPropagation();
	})*/
	useEffect(() => {
		if (hasFocus && !hasHadFocus) setHasHadFocus(true);

	}, [hasFocus]);
	useEffect(() => {
		if (refDiv.current) {
			svalue = escapeHTML(svalue);
			//console.log(svalue);
			svalue = svalue.replace(/(\/\*([^*]|\*[^\/])+\*\/)/gi, '<span class="sql-comment">$1</span>');
			svalue = svalue.replace(/(--[^\r\n]*)/gi, '<span class="sql-comment">$1</span>');
			svalue = svalue.replace(/(N?'([^'><]|'')*')/g, '<span class="sql-text">$1</span>');
			svalue = svalue.replace(/(^|[^a-z@_])(@[0-9a-z@_]+)/gi, '$1<span class="sql-pink">$2</span>');
			svalue = svalue.replace(wordregex, '$1<span class="sql-blue">$2</span>');
			refDiv.current.innerHTML = svalue;
			refDiv.current.style.height = refInput.current.offsetHeight + 'px';
			//refDiv.current.scrollLeft = refInput.current.scrollLeft-12
			//console.log(refInput.current.scrollLeft,refDiv.current.scrollLeft);
		}
	}, [svalue, refDiv.current, hasFocus]);
	useKey("Ctrl+Shift+U", (evt) => {
		let s = evt.target.selectionStart;
		let e = evt.target.selectionEnd;
		if (s < e) {
			document.execCommand('insertText', false, value.substring(s, e).toUpperCase());
			evt.target.setSelectionRange(s, e)
		}
	}, [])
	useKey("Ctrl+Shift+L", (evt) => {
		let s = evt.target.selectionStart;
		let e = evt.target.selectionEnd;
		if (s < e) {
			document.execCommand('insertText', false, value.substring(s, e).toLowerCase());
			evt.target.setSelectionRange(s, e)
		}
	}, [])
	//console.log('disabled', def.name, disabled, def.is_upd);
	return (<><div style={{ position: "absolute", right: 8, left: 8, zIndex: 9 }}>
		<div ref={refDiv} className={"form-control sql-control"} readOnly={def.is_upd === false} ></div>
	</div><textarea
			ref={refInput}
			className={"form-control sql-control"}
			name={def.name}
			disabled={disabled}
			onChange={(e) => { if (!disabled && def.is_upd !== false) setValue(e.target.value) }}
			onBlur={(e) => { setEditUpdatedfield(false); setHasFocus(false); if (!disabled && def.is_upd !== false) updateValue(e.target.value) }}
			onFocus={(evt) => { onFocus({ evt, refInput }); setHasFocus(true); }}
			value={value}
			onScroll={(e) => { refDiv.current.scrollLeft = e.target.scrollLeft }}
			onSelect={(evt) => {
				let s = evt.target.selectionStart;
				let e = evt.target.selectionEnd;
				let result = null;
				if (s === e && e > 0) {
					let part1 = value.substring(0, e);
					let part2 = value.substring(e);
					let val = part1.match(/([{[\].a-z0-9_]+)[ \r\n]$/i);
					//GB201022 false
					if (false && val && (lastKey === 'qwer' || lastKey === 'Enter')) {
						result = words.filter(word => word.match(new RegExp("^" + val[1].replace(/[.*+\-?^${}()|[\]\\]/g, '\\$&'), 'gi'))); //escape regexp
						//console.log('val',val,result)
						if (result.length && result[0] !== val[1]) {
							part1 = part1.replace(/[{[\].a-z0-9_]+([ \r\n])$/gi, result[0] + '$1');
							if (part2[0] && part2[0] === '}') part2 = part2.substring(1);
							setValue(part1 + part2);
							setTimeout(() => { refInput.current.setSelectionRange(part1.length, part1.length); }, 50);
						}
						result = null;
						setLastKey(null);
					}
					if (false) {
						val = part1.match(/[{[\].a-z0-9_]+$/gi);
						if (val) {
							result = words.filter(word => word.match(new RegExp("^" + val[0].replace(/[.*+\-?^${}()|[\]\\]/g, '\\$&'), 'gi')));
							if (result.length === 0) result = null;
						}
					}
				}
				//if (resultList !== result) setResultList(result);
			}}
			onDoubleClick={(evt) => {
				//console.log('sel_evt', value.substring())
				let e = evt.target.selectionEnd;
				let s = evt.target.selectionStart;
				//	console.log(s, e, 'setSelectionRange', value.substring(s, e))
				if (s < e && value[s] + value[s + 1] === "N'") s += 2;
				if (s < e && value[s].match(/[a-z0-9_@]/i)) {
					if (value && value[e - 1] && value[e - 1].match(/[ ]/)) e--;
					if (value && value[s - 1] && value[s - 1].match(/[@#]/)) s--;
					if (e !== evt.target.selectionEnd || s !== evt.target.selectionStart) evt.target.setSelectionRange(s, e);
				}
			}}
			onKeyDown={(evt) => setLastKey(evt.key)}
			onKeyPress={(evt) => {
				if (evt.key === 'Enter') {
					let s = evt.target.selectionStart;
					let match = value.substring(0, s).match(/(^|[\n\r])([ \t]+)[^\r\n]*$/)
					if (match && match[2]) {
						evt.preventDefault()
						document.execCommand('insertText', false, "\n" + match[2]);
					}
					//console.log(value.substring(0,s).match(/(^|[\n])([\s\t]+)[^\n]+$/))
					//document.execCommand('insertText', false, match[2]);
				}
			}}
			spellCheck="false"
			rows={(hasFocus || hasHadFocus) && value && value.split("\n").length > rows ? value.split("\n").length : rows}
			style={{ zIndex: 10, position: 'relative' }}
			readOnly={def.is_upd === false ? true : false}
			tabIndex={def.is_upd === false ? "-1" : "1"}
		/>
		{resultList ? <select className={"form-control"} style={{ position2: 'relative', zIndex2: 20, right2: 32, left2: 16 }}>{resultList.map((item, i) => <option key={item + i}>{item}</option>)}</select> : ''}

	</>)
}
function Textarea({ def, onFocus, data, disabled, value, setValue, updateValue, refInput, dispatch, setEditUpdatedfield }) {
	const [hasFocus, setHasFocus] = useState(false);
	let { rows, innercls } = def;
	let disp = value
	if (innercls === 'json') {
		innercls = 'pre'
		try {
			let obj = JSON.parse(disp)
			disp = JSON.stringify(obj, null, 4)
		} catch (e) {
			innercls += ' bg-danger text-white'
		}
	}

	return (<textarea
		ref={refInput}
		className={"form-control " + (innercls ?? '')}
		name={def.name}
		disabled={disabled}
		onKeyDown={(e) => {
			if (e.key === "Insert") {
				dispatch({ type: 'pre_action', action: 'save' });
				e.target.blur()
			}
		}}
		onChange={(e) => { if (!disabled) setValue(e.target.value) }}
		onBlur={(e) => { setEditUpdatedfield(false); setHasFocus(false); if (!disabled) updateValue(e.target.value) }}
		onFocus={(evt) => { onFocus({ evt, refInput }); setHasFocus(true); }}
		value={disp}
		spellCheck="false"
		rows={hasFocus && value && value.split("\n").length > rows ? value.split("\n").length + 1 : rows}
		style={{ maxHeight: rows ? 'none' : '500px' }}
		readOnly={def.is_upd === false ? true : false}
		tabIndex={def.is_upd === false ? "-1" : "1"}
		maxLength={def.len === 0 ? 100000000 : def.len} //gb221103
	/>)
}

function DateInput({ def, onFocus, data, disabled, type, value, valid, setValue, updateValue, refInput }) {
	//const date = new Date(Date.parse(value));
	//let parsedValue = Date.parse(value);
	//if (!parsedValue) parsedValue = null;
	//console.log(Date.parse(value),'value');
	//const [serverDate, setServerDate] = useState(new Date(parseDate(value)));
	const [serverDate, setServerDate] = useState(parseDate(value));
	const [startDate, setStartDate] = useState(serverDate);
	/*
	if (serverDate !== startDate) {
		updateValue(toLocalISOString(startDate));
		setServerDate(startDate);
	}*/
	const updateServer = () => {
		//console.log(startDate, serverDate);
		if (toLocalISOString(serverDate) !== toLocalISOString(startDate)) {
			updateValue(toLocalISOString(startDate));
			setServerDate(parseDate(toLocalISOString(startDate)))
		}
	}
	useEffect(() => {
		//console.log(startDate, serverDate, 'startDate', def.name);
		if (refInput.current.input !== document.activeElement) updateServer()
		//		console.log(startDate,serverDate, 'startDate', def.name);
	}, [startDate]);
	useEffect(() => {
		setServerDate(parseDate(value))
	}, [value]);
	useEffect(() => {
		setStartDate(serverDate)
	}, [serverDate]);
	return <DatePicker
		ref={refInput}
		type={'date'}
		//min="1900-01-01"
		//max="3000-12-31"
		//defaultChecked={data}
		//autoComplete="off"
		maxDate={new Date(Date.parse("2500-12-31"))}
		minDate={new Date(Date.parse("1900-01-01"))}
		dateFormat={getDateFormat()}
		//autoFocus={def.id === 2 && !disabled}
		selected={startDate}
		disabled={disabled}
		autoFocus={false}
		//value={formatDate(value)}
		//value2={value.split('T')[0]}
		className={"form-control " + (def.regex === null ? '' : valid ? 'is-valid' : 'is-invalid')}
		//onChange={(e) => { if (!disabled) setValue(e.target.value) }}
		//onChange={date => {if (!disabled) updateValue(date)}}
		onChange={date => {
			//console.log('DateInput onChange', date, serverDate, startDate)
			setStartDate(date)
		}}
		onCalendarClose={e => {
			//console.log('onCalendarClose', e, serverDate, startDate)
			if (!disabled) updateServer()
		}}
		/*onBlur={(e) => {
			console.log(disabled)
			if (!disabled) console.log('DateInput blur', serverDate, startDate);
			if (!disabled) updateServer()
		}}*/
		//onFocus={(e) => { if (disabled) e.target.blur(); }}
		onFocus={(evt) => {
			onFocus({ evt, refInput })
		}}
		readOnly={!def.is_upd}
		tabIndex={def.is_upd ? "1" : "-1"}
		maxLength={def.len === 0 ? 1000000 : def.len}
	//onKeyDown={}
	/>
}
function Time() {
	return <input />
}

function Checkbox({ value, def, updateValue, disabled, onFocus, refInput }) {
	const [newValue, setVal] = useState(value);
	useEffect(() => {
		if (newValue !== value) updateValue(newValue);
		refInput.current.indeterminate = newValue === null;
	}, [newValue])
	useEffect(() => {
		if (newValue !== value) setVal(value);
	}, [value])
	let readonly = disabled || !def.is_upd;
	//readonly = false;
	return (<>
		<input
			className={(readonly ? 'readonly' : '')}
			ref={refInput}
			//id={"chkbox" + def.id}
			checked={newValue === true}
			type="checkbox"
			tabIndex={def.is_upd ? "1" : "-1"}
			onMouseDown={(evt) => evt.preventDefault()}
			//indeterminate={newValue === null}
			onFocus={(evt) => {
				onFocus({ evt, refInput });
				// evt.target.blur()
			}}
			//onFocus={(evt) => onFocus({ evt, refInput })}
			onChange={(evt) => { if (readonly) return; setVal(newValue === null ? true : (newValue ? false : (def.is_nul ? null : true))) }}
		//ref={el => el && (el.indeterminate = newValue === null)}
		/>
		<label className={"form-check-label" + (readonly ? ' readonly' : '')
			// + (def.is_mod ? ' modified' : '')
		} htmlFor={"chkbox" + def.id}>{def.disp}
			{
				//<small><small>{def.att}{def.is_nul ? '' : ' NOT NULL'}</small></small>
			}
		</label>
	</>
	);
}
